import React, { FormEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";

const Form: React.FC = () => {
  const usernameRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const username = usernameRef.current?.value.toLowerCase();
    if (!username) return;

    navigate("/" + username);
  };

  return (
    <form
      className="w-full flex flex-col items-center gap-2 mt-4"
      onSubmit={onSubmit}
    >
      <label className="flex flex-col items-center gap-1 w-full">
        <span className="font-bold">Kullanıcı Adınız</span>
        <input
          type="text"
          ref={usernameRef}
          spellCheck="false"
          className="w-full"
          style={{
            padding: ".375rem .75rem",
            color: "#212529",
            border: "1px solid #ced4da",
            fontSize: "1rem",
            borderRadius: ".25rem",
          }}
          required
        />
      </label>

      <button
        type="submit"
        style={{
          border: "1px solid #CAA75F",
          background: "linear-gradient(45deg, #8a662d, #d0ad65)",
          padding: ".375rem .75rem",
          borderRadius: ".25rem",
          transition:
            "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        }}
        className="font-bold text-white w-full hover:!border-black"
      >
        Tahminde Bulun / Tahminini Gör
      </button>

      <button
        type="button"
        style={{
          border: "1px solid #CAA75F",
          background: "linear-gradient(45deg, #8a662d, #d0ad65)",
          padding: ".375rem .75rem",
          borderRadius: ".25rem",
          transition:
            "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        }}
        className="font-bold text-white w-full hover:!border-black"
        onClick={() => navigate("/past-weeks")}
      >
        Geçmiş Sonuçları Göster
      </button>

      <button
        type="button"
        style={{
          border: "1px solid #CAA75F",
          background: "linear-gradient(45deg, #8a662d, #d0ad65)",
          padding: ".375rem .75rem",
          borderRadius: ".25rem",
          transition:
            "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        }}
        className="font-bold text-white w-full hover:!border-black"
        onClick={() => navigate("/rules")}
      >
        Katılım Şartları
      </button>
    </form>
  );
};

export default Form;
