import axios from "axios";
import Table from "rc-table";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layover from "src/components/Layover";
import "../index.css";

const WeekResult: React.FC = () => {
  const navigate = useNavigate();
  const { weekId } = useParams();
  const [data, setData] = useState<null | {
    name: string;
    id: number;
    matches: {
      id: number;
      homeTeam: {
        name: string;
      };
      awayTeam: {
        name: string;
      };
      result: string;
    }[];
  }>(null);

  useEffect(() => {
    const main = async () => {
      const { data } = await axios.get(
        "https://extrapanel.herokuapp.com/api/getPastWeeks?id=" + weekId
      );

      setData(data);
    };

    main();
  }, []);

  return (
    <Layover>
      <button
        style={{
          border: "1px solid #CAA75F",
          background: "linear-gradient(45deg, #8a662d, #d0ad65)",
          padding: ".25rem .5rem",
          borderRadius: ".2rem",
          fontSize: ".875rem",
          transition:
            "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        }}
        className="text-white hover:!border-black !cursor-pointer"
        onClick={() => navigate(-1)}
      >
        Geri Git
      </button>

      {data && (
        <div className="mt-4">
          <h1 className="font-bold text-3xl mb-4 text-center">
            {data.name} Sonuçları
          </h1>

          <Table
            emptyText=""
            data={data.matches}
            rowKey={(rec) => rec.id}
            columns={[
              {
                key: "home",
                align: "center",
                title: "Ev Sahibi",
                render: (_, rec) => rec.homeTeam.name,
              },
              {
                key: "away",
                align: "center",
                title: "Deplasman",
                render: (_, rec) => rec.awayTeam.name,
              },
              {
                key: "result",
                align: "center",
                title: "Maç Sonucu",
                render: (_, rec) => {
                  if (!rec.result) {
                    return "Sonuçlanmadı";
                  } else if (rec.result === "cancelled") {
                    return "İptal Oldu";
                  } else if (rec.result === "ms0") {
                    return "(X) Berabere";
                  }

                  return rec.result === "ms1"
                    ? "(1) " + rec.homeTeam.name
                    : "(2) " + rec.awayTeam.name;
                },
              },
            ]}
          />
        </div>
      )}
    </Layover>
  );
};

export default WeekResult;
