import axios from "axios";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import Matches from "src/components/Matches";
import "react-toastify/dist/ReactToastify.css";
import "../guess.css";

export type PredictionType = {
  id: number;
  username: number;
  matches: {
    choice: string;
  }[];
};

const Guess: React.FC = () => {
  const { username } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [closingAt, setClosingAt] = useState("");
  const [activeWeek, setActiveWeek] = useState<null | {
    id: number;
    name: string;
    closingDate: string;
    matches: {
      id: number;
      homeTeam: {
        id: number;
        name: string;
        photo: {
          data: Buffer;
        };
      };
      awayTeam: {
        id: number;
        name: string;
        photo: {
          data: Buffer;
        };
      };
      startingAt: string;
      result: string | null;
    }[];
  }>(null);
  const [clientPredictions, setClientPredictions] =
    useState<null | PredictionType>(null);

  useEffect(() => {
    if (!username) return;

    const main = async () => {
      const { data: activeWeek } = await axios.get(
        `https://extrapanel.herokuapp.com/api/getActiveWeek`
      );
      if (!activeWeek) {
        return;
      }

      const { data: clientPredictions } = await axios.get(
        `https://extrapanel.herokuapp.com/api/getClientPredictions?username=${username}&weekId=${activeWeek.id}`
      );

      setActiveWeek(activeWeek);
      setClientPredictions(clientPredictions);
      setIsFetching(false);
    };

    main();
  }, [username]);
  useEffect(() => {
    if (!activeWeek) return;

    const date = DateTime.fromFormat(activeWeek.closingDate, "dd-MM-yy HH:mm");

    const func = () => {
      const now = DateTime.now().setZone("UTC+3");

      if (now > date) {
        setClosingAt("KATILIM SONA ERDİ");

        return;
      }

      const { days, hours, minutes, seconds } = date
        .diff(now, ["day", "hour", "minute", "second"])
        .toObject();

      const closingAt = `${Math.floor(days!)} gün ${Math.floor(
        hours!
      )} saat ${Math.floor(minutes!)} dakİka ${Math.floor(seconds!)} sanİye`;
      setClosingAt(closingAt);
    };
    func();

    const interval = setInterval(func, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [activeWeek]);

  return (
    <div
      className="w-full h-full flex"
      style={{
        backgroundColor: "#edeff4",
      }}
    >
      {isFetching ? (
        <div className="m-auto">
          <svg
            className="animate-spin -ml-1 mr-3 h-10 w-10 text-yellow-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        activeWeek && (
          <div
            className="w-full"
            style={{
              padding: "10px 15px 0",
            }}
          >
            <header
              className="flex flex-col lg:flex-row gap-3 lg:gap-0 justify-between items-center w-full"
              style={{
                marginBottom: "11px",
              }}
            >
              <h1
                className="font-bold text-base lg:text-lg uppercase before:absolute relative before:border-l-4 
                  before:border-solid before:border-yellow-600 before:left-0 before:top-1 before:bottom-1 pl-4 tracking-wider"
              >
                extratoto {activeWeek.name} ({username})
              </h1>
              <div
                className="uppercase font-bold text-white"
                style={{
                  background: "linear-gradient(47deg, #8a662d, #d0ad65)",
                  borderRadius: ".25em",
                  padding: "6px 14px",
                  fontSize: "12px",
                  lineHeight: "1",
                }}
              >
                {closingAt}
              </div>
            </header>

            <main>
              <div
                className="flex flex-row justify-between items-center text-white"
                style={{
                  background: "linear-gradient(358deg, #8a662d, #d0ad65)",
                  fontSize: "14px",
                  letterSpacing: ".1em",
                  borderRadius: "5px 5px 0 0",
                  padding: "12px 23px 8px",
                }}
              >
                <h2>HAFTANIN KARŞILAŞMALARI</h2>
                <img
                  src="/extrabetlogo.png"
                  alt="logo"
                  width="22px"
                  height="22px"
                />
              </div>

              <Matches
                clientPredictions={clientPredictions}
                activeWeek={activeWeek}
              />
            </main>
          </div>
        )
      )}
      <ToastContainer transition={Slide} />
    </div>
  );
};

export default Guess;
