import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const Layover: React.FC<Props> = ({ children }) => (
  <div
    className="w-full h-full flex"
    style={{
      background: "url(/bg.jpg) no-repeat center center fixed",
      backgroundSize: "cover",
    }}
  >
    <div
      className="m-auto text-white p-4"
      style={{
        border: "1px solid #333333",
        borderRadius: "25px",
        background: "linear-gradient(45deg, #050505e0, #3c3c3ce0)",
        width: "750px",
      }}
    >
      {children}
    </div>
  </div>
);

export default Layover;
