import axios from "axios";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PredictionType } from "src/routes/Guess";
import Match from "./Match";

interface Props {
  clientPredictions: PredictionType | null;
  activeWeek: {
    id: number;
    name: string;
    closingDate: string;
    matches: {
      id: number;
      homeTeam: {
        id: number;
        name: string;
        photo: {
          data: Buffer;
        };
      };
      awayTeam: {
        id: number;
        name: string;
        photo: {
          data: Buffer;
        };
      };
      startingAt: string;
      result: string | null;
    }[];
  };
}

const Matches: React.FC<Props> = ({ clientPredictions, activeWeek }) => {
  const navigate = useNavigate();
  const { username } = useParams();
  const [predictions, setPredictions] = useState({
    1: {
      prediction: clientPredictions
        ? clientPredictions.matches[0]
          ? clientPredictions.matches[0].choice
          : ""
        : "",
      isDone: activeWeek.matches[0].result
        ? true
        : DateTime.fromFormat(
            activeWeek.matches[0].startingAt,
            "dd-MM-yy HH:mm"
          ) > DateTime.now().setZone("UTC+3")
        ? false
        : true,
    },
    2: {
      prediction: clientPredictions
        ? clientPredictions.matches[1]
          ? clientPredictions.matches[1].choice
          : ""
        : "",
      isDone: activeWeek.matches[1].result
        ? true
        : DateTime.fromFormat(
            activeWeek.matches[1].startingAt,
            "dd-MM-yy HH:mm"
          ) > DateTime.now().setZone("UTC+3")
        ? false
        : true,
    },
    3: {
      prediction: clientPredictions
        ? clientPredictions.matches[2]
          ? clientPredictions.matches[2].choice
          : ""
        : "",
      isDone: activeWeek.matches[2].result
        ? true
        : DateTime.fromFormat(
            activeWeek.matches[2].startingAt,
            "dd-MM-yy HH:mm"
          ) > DateTime.now().setZone("UTC+3")
        ? false
        : true,
    },
    4: {
      prediction: clientPredictions
        ? clientPredictions.matches[3]
          ? clientPredictions.matches[3].choice
          : ""
        : "",
      isDone: activeWeek.matches[3].result
        ? true
        : DateTime.fromFormat(
            activeWeek.matches[3].startingAt,
            "dd-MM-yy HH:mm"
          ) > DateTime.now().setZone("UTC+3")
        ? false
        : true,
    },
    5: {
      prediction: clientPredictions
        ? clientPredictions.matches[4]
          ? clientPredictions.matches[4].choice
          : ""
        : "",
      isDone: activeWeek.matches[4].result
        ? true
        : DateTime.fromFormat(
            activeWeek.matches[4].startingAt,
            "dd-MM-yy HH:mm"
          ) > DateTime.now().setZone("UTC+3")
        ? false
        : true,
    },
    6: {
      prediction: clientPredictions
        ? clientPredictions.matches[5]
          ? clientPredictions.matches[5].choice
          : ""
        : "",
      isDone: activeWeek.matches[5].result
        ? true
        : DateTime.fromFormat(
            activeWeek.matches[5].startingAt,
            "dd-MM-yy HH:mm"
          ) > DateTime.now().setZone("UTC+3")
        ? false
        : true,
    },
    7: {
      prediction: clientPredictions
        ? clientPredictions.matches[6]
          ? clientPredictions.matches[6].choice
          : ""
        : "",
      isDone: activeWeek.matches[6].result
        ? true
        : DateTime.fromFormat(
            activeWeek.matches[6].startingAt,
            "dd-MM-yy HH:mm"
          ) > DateTime.now().setZone("UTC+3")
        ? false
        : true,
    },
    8: {
      prediction: clientPredictions
        ? clientPredictions.matches[7]
          ? clientPredictions.matches[7].choice
          : ""
        : "",
      isDone: activeWeek.matches[7].result
        ? true
        : DateTime.fromFormat(
            activeWeek.matches[7].startingAt,
            "dd-MM-yy HH:mm"
          ) > DateTime.now().setZone("UTC+3")
        ? false
        : true,
    },
    9: {
      prediction: clientPredictions
        ? clientPredictions.matches[8]
          ? clientPredictions.matches[8].choice
          : ""
        : "",
      isDone: activeWeek.matches[8].result
        ? true
        : DateTime.fromFormat(
            activeWeek.matches[8].startingAt,
            "dd-MM-yy HH:mm"
          ) > DateTime.now().setZone("UTC+3")
        ? false
        : true,
    },
    10: {
      prediction: clientPredictions
        ? clientPredictions.matches[9]
          ? clientPredictions.matches[9].choice
          : ""
        : "",
      isDone: activeWeek.matches[9].result
        ? true
        : DateTime.fromFormat(
            activeWeek.matches[9].startingAt,
            "dd-MM-yy HH:mm"
          ) > DateTime.now().setZone("UTC+3")
        ? false
        : true,
    },
  });

  return (
    <>
      <div className="w-full bg-white">
        {activeWeek.matches.map(
          ({ id, homeTeam, awayTeam, startingAt, result }, idx) => (
            <Match
              key={id}
              predictions={predictions}
              disabled={!!clientPredictions}
              setPredictions={setPredictions}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              startingAt={startingAt}
              number={idx + 1}
              result={result}
            />
          )
        )}
      </div>

      <button
        type="button"
        style={{
          background: "linear-gradient(45deg, #8a662d, #d0ad65)",
          border: "1px solid #CAA75F",
          color: "white",
          borderRadius: "3px",
          textTransform: "uppercase",
          letterSpacing: ".05em",
          fontWeight: 500,
          width: "100%",
          padding: "0.375rem 0.75rem",
        }}
        className="mt-4 mb-8 lg:mb-0"
        onClick={async () => {
          if (clientPredictions) {
            toast.error("Daha öncesinde tahminde bulundunuz.");

            return;
          }

          const choices = Object.values(predictions);
          for (const { isDone, prediction } of choices) {
            if (!isDone && !prediction) {
              toast.error("Lütfen bütün karşılaşmalara tahminde bulununuz.");

              return;
            }
          }

          const matches = activeWeek.matches.map(
            ({ homeTeam, awayTeam }, idx) => ({
              homeTeamId: homeTeam.id,
              awayTeamId: awayTeam.id,
              choice: predictions[idx + 1].isDone
                ? "done"
                : predictions[idx + 1].prediction,
              startingAt: activeWeek.matches[idx].startingAt,
            })
          );

          const { data } = await axios.post(
            "https://extrapanel.herokuapp.com/api/sendPredictions",
            {
              username: username?.toLowerCase(),
              weekId: activeWeek.id,
              matches: matches,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (data.ok) {
            toast.success("Tahmininiz yollandı.");

            navigate(0);

            return;
          }

          toast.error(data.message || "Tahmininiz yollanamadı.");
        }}
      >
        Tahmİnlerİ Gönder
      </button>
    </>
  );
};

export default Matches;
