import React from "react";
import ReactDOM from "react-dom/client";
import Home from "./routes/Home";
import Rules from "./routes/Rules";
import PastWeeks from "./routes/PastWeeks";
import WeekResult from "./routes/WeekResult";
import Guess from "./routes/Guess";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/rules",
    element: <Rules />,
  },
  {
    path: "/past-weeks",
    element: <PastWeeks />,
  },
  {
    path: "/past-weeks/:weekId",
    element: <WeekResult />,
  },
  {
    path: "/:username",
    element: <Guess />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
