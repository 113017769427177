import { DateTime } from "luxon";
import React, { Dispatch, SetStateAction } from "react";

interface Props {
  predictions: Record<
    number,
    {
      prediction: string;
      isDone: boolean;
    }
  >;
  setPredictions: Dispatch<
    SetStateAction<
      Record<
        number,
        {
          prediction: string;
          isDone: boolean;
        }
      >
    >
  >;
  disabled: boolean;
  homeTeam: {
    name: string;
    photo: {
      data: Buffer;
    };
  };
  awayTeam: {
    name: string;
    photo: {
      data: Buffer;
    };
  };
  startingAt: string;
  number: number;
  result: string | null;
}

const Match: React.FC<Props> = ({
  disabled,
  homeTeam,
  awayTeam,
  predictions,
  setPredictions,
  startingAt,
  number,
  result,
}) => {
  const arrayBufferToBase64 = (buffer: Buffer) => {
    let binary = "";
    const bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b: any) => (binary += String.fromCharCode(b)));

    const base64Flag = "data:image/jpeg;base64,";
    const imageStr = window.btoa(binary);

    return base64Flag + imageStr;
  };

  return (
    <div
      className="w-full flex flex-col lg:flex-row items-center h-fit lg:h-65 gap-2 lg:gap-0"
      style={{
        padding: "11px 30px",
        border: "1px solid #e1e1e1",
        borderTop: "none",
      }}
    >
      <div className="flex flex-row justify-between items-center w-full lg:w-500">
        <div
          style={{
            backgroundImage: "url(./icons.png)",
            height: "25px",
            width: "25px",
            backgroundPosition: "-166px 0",
          }}
          className="hidden lg:block"
        />
        <div className="w-80 lg:ml-8">
          <div className="flex flex-row items-center gap-2">
            <img
              src={arrayBufferToBase64(homeTeam.photo.data)}
              alt={homeTeam.name}
              height="16"
              width="16"
            />
            <span>{homeTeam.name}</span>
          </div>
          <div className="flex flex-row items-center gap-2">
            <img
              src={arrayBufferToBase64(awayTeam.photo.data)}
              alt={awayTeam.name}
              height="16"
              width="16"
            />
            <span>{awayTeam.name}</span>
          </div>
        </div>
        <div
          className="flex flex-row gap-1 items-center relative"
          style={{
            color: "#9e7a3d",
          }}
        >
          {(() => {
            const startingDate = DateTime.fromFormat(
              startingAt,
              "dd-MM-yy HH:mm"
            );
            const now = DateTime.now().setZone("UTC+3");

            if (now > startingDate && !result) {
              return (
                <div className="text-green-500 absolute right-20 w-28 hidden sm:block text-center font-bold">
                  Maç Başladı!
                </div>
              );
            }

            return;
          })()}
          <div className="flex flex-col gap-1 items-center">
            <span>{startingAt.split(" ")[0].replace(/-/g, ".")}</span>
            <span>{startingAt.split(" ")[1]}</span>
          </div>
        </div>
      </div>

      <hr
        className="h-full hiden sm:block bg-gray-100 ml-8"
        style={{
          width: "2px",
        }}
      />

      <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4 sm:ml-8 w-full">
        <button
          type="button"
          style={{
            background:
              result === "ms1"
                ? "linear-gradient(47deg, #8a662d, #d0ad65)"
                : predictions[number].prediction === homeTeam.name
                ? "linear-gradient(47deg, #8a662d, #d0ad65)"
                : "#eaecee",
            transition: "all 0.15s ease-in-out",
            lineHeight: "1.71429",
            padding: "7px",
            borderRadius: "5px",
            fontSize: "12px",
            color:
              result === "ms1"
                ? "white"
                : predictions[number].prediction === homeTeam.name
                ? "white"
                : "black",
          }}
          className="choice-btn w-full sm:w-200"
          disabled={disabled}
          onClick={() =>
            !disabled &&
            !result &&
            !predictions[number].isDone &&
            setPredictions((predictions) => ({
              ...predictions,
              [number]: {
                prediction: homeTeam.name,
                isDone: false,
              },
            }))
          }
        >
          {homeTeam.name}
        </button>
        <button
          type="button"
          style={{
            background:
              result === "ms0"
                ? "linear-gradient(47deg, #8a662d, #d0ad65)"
                : predictions[number].prediction === "draw"
                ? "linear-gradient(47deg, #8a662d, #d0ad65)"
                : "#eaecee",
            transition: "all 0.15s ease-in-out",
            lineHeight: "1.71429",
            padding: "7px",
            borderRadius: "5px",
            fontSize: "12px",
            color:
              result === "ms0"
                ? "white"
                : predictions[number].prediction === "draw"
                ? "white"
                : "black",
          }}
          className="choice-btn w-full sm:w-200"
          disabled={disabled}
          onClick={() =>
            !disabled &&
            !result &&
            !predictions[number].isDone &&
            setPredictions((predictions) => ({
              ...predictions,
              [number]: {
                prediction: "draw",
                isDone: false,
              },
            }))
          }
        >
          Berabere
        </button>
        <button
          type="button"
          style={{
            background:
              result === "ms2"
                ? "linear-gradient(47deg, #8a662d, #d0ad65)"
                : predictions[number].prediction === awayTeam.name
                ? "linear-gradient(47deg, #8a662d, #d0ad65)"
                : "#eaecee",
            transition: "all 0.15s ease-in-out",
            lineHeight: "1.71429",
            padding: "7px",
            fontSize: "12px",
            borderRadius: "5px",
            color:
              result === "ms2"
                ? "white"
                : predictions[number].prediction === awayTeam.name
                ? "white"
                : "black",
          }}
          className="choice-btn w-full sm:w-200"
          disabled={disabled}
          onClick={() =>
            !disabled &&
            !result &&
            !predictions[number].isDone &&
            setPredictions((predictions) => ({
              ...predictions,
              [number]: {
                prediction: awayTeam.name,
                isDone: false,
              },
            }))
          }
        >
          {awayTeam.name}
        </button>
      </div>
    </div>
  );
};

export default Match;
