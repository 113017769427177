import React from "react";
import Form from "src/components/Form";
import Layover from "src/components/Layover";
import "../index.css";

const Home: React.FC = () => (
  <Layover>
    <header className="w-full flex">
      <img
        src="/logo.png"
        alt="logo"
        className="m-auto"
        style={{
          width: "600px",
        }}
      />
    </header>

    <Form />

    <footer>
      <img
        src="/totoprizes.png"
        alt="prizes"
        className="m-auto"
        style={{
          width: "600px",
        }}
      />
    </footer>
  </Layover>
);

export default Home;
