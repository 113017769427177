import axios from "axios";
import Table from "rc-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layover from "src/components/Layover";
import "../index.css";

const PastWeeks: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<
    | null
    | {
        name: string;
        closingDate: string;
        id: number;
      }[]
  >(null);

  useEffect(() => {
    const main = async () => {
      const { data } = await axios.get(
        "https://extrapanel.herokuapp.com/api/getPastWeeks"
      );

      setData(data);
    };

    main();
  }, []);

  return (
    <Layover>
      <button
        style={{
          border: "1px solid #CAA75F",
          background: "linear-gradient(45deg, #8a662d, #d0ad65)",
          padding: ".25rem .5rem",
          borderRadius: ".2rem",
          fontSize: ".875rem",
          transition:
            "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        }}
        className="text-white hover:!border-black !cursor-pointer"
        onClick={() => navigate(-1)}
      >
        Geri Git
      </button>

      {data && (
        <div className="mt-4">
          <Table
            emptyText=""
            data={data}
            rowKey={(rec) => rec.id}
            columns={[
              {
                key: "name",
                title: "Hafta Adı",
                align: "center",
                dataIndex: "name",
              },
              {
                key: "date",
                title: "Son Katılım Tarihi",
                align: "center",
                dataIndex: "closingDate",
              },
              {
                key: "results",
                title: "Sonuçlar",
                align: "center",
                render: (_, rec) => (
                  <button
                    style={{
                      background: "linear-gradient(45deg, #8a662d, #d0ad65)",
                      border: "1px solid #CAA75F",
                      borderRadius: ".2rem",
                      fontSize: ".875rem",
                      padding: ".25rem .5rem",
                      transition:
                        "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                    }}
                    className="text-white hover:!border-black"
                    onClick={() => navigate(`/past-weeks/${rec.id}`)}
                  >
                    Sonuçları Gör
                  </button>
                ),
              },
            ]}
          />
        </div>
      )}
    </Layover>
  );
};

export default PastWeeks;
